
export const SIGNUP_SUCCESS_MESSAGE =   <>
<img src="/images/icons/tick-circle.svg" />
<p style={{color: "green"}}>Please confirm your account registration via the link sent to your email.</p>
</>

export const SIGNUP_FAILED_MESSAGE =   <>
<img src="/images/icons/error.svg" />
<p style={{color: "red"}}>Failed to signup</p>
</>

export const CREATE_DISCOUNT_SUCCESS_MESSAGE =   <>
<img src="/images/icons/tick-circle.svg" />
<p style={{color: "green"}}>
    Your discount details have been successfully submited. 
    Quick Discounts review team would reach out to you via the email you provided
    in less than 24hrs.
</p>
</>

export const DISCOUNT_UPDATE_SUCCESS_MESSAGE =   <>
<img src="/images/icons/tick-circle.svg" />
<p style={{color: "green"}}>Event updated successfully.</p>
</>


export const CREATE_DISCOUNT_FAILED_MESSAGE =   <>
<img src="/images/icons/error.svg" />
<p style={{color: "red"}}>Failed to create event.</p>
</>