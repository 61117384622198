import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

import BackgroundSlider from "./BackgroundSlider";
import CarouselFlex from "../Shared/CarouselFlex";
import TopDiscounts from "../Discounts/TopDiscounts";
import { LeftButton, RightButton } from "../Shared/CarouselControls";
import DiscountCard from "../Discounts/DiscountCard";

import { getDiscountsAPI } from "../../actions";

const Home = (props) => {
  const [categories, setCategories] = useState();
  // top discounts
  const [topDiscounts, setTopDiscounts] = useState();


  useEffect(() => {
    props.getDiscounts();
    console.log("Getting Discounts ...");
  }, []);

  useEffect(() => {
    if (props.discounts.results) {
      // Get categories of fectched discounts
      const categories_lists = props.discounts.results.map(
        (discount) => discount.categories
      );
      console.log("AAA ", categories_lists);
      // Create an empty Set to store unique values
      const categories_set = new Set();

      // Loop through each sub-list in A and add its elements to the resultSet
      categories_lists.forEach((subList) => {
        subList.forEach((category) => {
          categories_set.add(category.name);
        });
      });
      // Unpack the set into a list
      let allCategories = [...categories_set];
      console.log("All categories ", allCategories);
      setCategories(allCategories);

      // a function to get all discounts with most likes as topDiscounts
      const top_discounts = props.discounts.results.sort((a, b) => b.likes - a.likes).slice(0, 4);
      setTopDiscounts(top_discounts);
    };
  }, [props.discounts]);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id.toLowerCase() + "-section");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <>
        <BackgroundSlider />
      </>

      <Content>
        {topDiscounts && 
          <TopDiscounts discounts={topDiscounts} />}     

        <Section>
          <LeftButton target="filter" pos='0'/>
          
          <Categories
            id="filter"
            role="region"
            aria-label="categories-filter"
            tabIndex="0"
          >
            {categories && (
              <>
                <span className="active" onClick={() => handleClickScroll("popular")}>
                  {categories[0]}
                </span>
                {categories.slice(1).map((category, key) => (
                  <span key={key} onClick={() => handleClickScroll(category)}>
                    {category}
                  </span>
                ))}
              </>
            )}
          </Categories>
          
          <RightButton target="filter" pos='0'/>
        </Section>

        <CategoriesWrap>
          {categories && (
            <>
              {categories.map((category, key) => (
                <CategorySection
                  key={key}
                  id={`${category.toLowerCase()}-section`}
                  index={key}
                  className="category-section"
                >
                  <CategoryTitle>
                    <h4>{category}</h4>
                    <h4>
                      <Link to={`/discounts/cat/${category.toLowerCase()}`}>
                        See more
                      </Link>
                    </h4>
                  </CategoryTitle>
                  {props.discounts.results && (
                    <CarouselFlex
                      type="category"
                      divId={category.toLowerCase()}
                      className="category-carousel-section"
                    >           
                      {/* get discounts that belong to current category  */}
                      {props.discounts.results.filter(
                      (discount) =>
                        discount.categories.map(cat => cat.name).includes(category)
                      ).slice(0,4).map((discount, key) => (
                        <DiscountCard
                        key={key}
                        discount={discount}
                        />
                        ))}
                    </CarouselFlex>
                  )}
                </CategorySection>
              ))}
            </>
          )}
        </CategoriesWrap>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  max-width: 100%;
  position: relative;
  font-family: Inter, 'Roboto', sans-serif;
`;

const Content = styled.div`
  max-width: 100%;
  position: relative;

  @media (min-width: 768px) {
    width: 75%;
    margin: 0 auto;
  }

  /* Largest devices such as desktops (1920px and up) */
  @media only screen and (min-width: 120em) {
    width: 65%;
    margin: 0 auto;
  }

  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 160em) {
    width: 50%;
    margin: 0 auto;
  }
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  margin: 10px 0;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Categories = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x proximity;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* border: 1px solid black; */

  span {
    margin: 5px 10px;
    padding: 8px 20px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 16px;
    flex-shrink: 0;
    scroll-snap-align: center;

    &:first-of-type {
      scroll-snap-align: start;
    }
    &:last-of-type {
      scroll-snap-align: end;
    }

    &:hover,
    &.active {
      background-color: #67309b;
      color: white;
      cursor: default;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  /* @media (min-width: 768px) {
    margin: 0 auto;
    width: 80%;
  } */
`;

const CategoriesWrap = styled.div`
  margin-top: 5px;
  max-width: 100%;
`;

const CategorySection = styled.div`
  width: 100%;
  margin: 10px 0;
  padding-bottom: 10px;
  /* border: 1px solid blue; */

  &.category-section {
    background: ${(props) => (props.index % 2 === 0 ? "#fff" : "#fbfbfb")};
    background-image: ${(props) =>
      props.bgImage ? `url(${props.bgImage})` : "none"};
  }
`;

const CategoryTitle = styled.div`
  color: #fa8128;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 5px;
    padding-top: 15px;
    font-size: 22px;
    /* font-weight: 600; */
    a {
      color: #808080;
      font-size: 16px;
      text-decoration: none;
      &:hover {
        cursor: default;
      }
    }
  }
  /* @media (min-width: 768px) {
    width: 90%;
    margin: 0 auto;
  } */

  /* Largest devices such as desktops (1920px and up) */
  /* @media only screen and (min-width: 120em) {
    width: 80%;
    margin: 0 auto;
  } */

  /* Largest devices such as desktops (1280px and up) */
  /* @media only screen and (min-width: 160em) {
    width: 60%;
    margin: 0 auto;
  } */
`;

const Popular = styled(CategorySection)`
  margin-top: 20px;
`;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
    token: state.userState.token,
    discounts: state.discountState.discounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDiscounts: () => {
    dispatch(getDiscountsAPI());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
