import { SET_DISCOUNTS, 
    SET_CATEGORIES, 
    SET_CREATE_DISCOUNT_STATUS, 
    SET_CART_ITEMS,
    SET_WISH_LIST, 
    SET_DISCOUNT_PACKAGES,
    SET_DISCOUNT_MEDIA,
    SET_DISCOUNT_REVIEWS,
    SET_SEARCH_RESULT,
    USER_DISCOUNT_LIKE
 } from "../actions/actionType";

export const initState = {
    discounts: sessionStorage.getItem('discounts') ?
    JSON.parse(sessionStorage.getItem('discounts')) : [], 
    items: sessionStorage.getItem('cartItems') ?
    JSON.parse(sessionStorage.getItem('cartItems')) : [],
    totalAmount: sessionStorage.getItem('totalAmount') ?
    +JSON.parse(sessionStorage.getItem('totalAmount')) : 0,
    categories: sessionStorage.getItem('categories') ?
    JSON.parse(sessionStorage.getItem('categories')) : null,
    createDiscountStatus: null,
    wishlist: sessionStorage.getItem('wishlist') ?
    JSON.parse(sessionStorage.getItem('wishlist')) : [],    
    discount_packages: sessionStorage.getItem('discount_packages') ?
    JSON.parse(sessionStorage.getItem('discount_packages')) : null,
    reviews: sessionStorage.getItem('reviews') ?
    JSON.parse(sessionStorage.getItem('reviews')) : null, 
    search_result: sessionStorage.getItem('search_result') ?
    JSON.parse(sessionStorage.getItem('search_result')) : [],  
    user_discount_like: sessionStorage.getItem('user_discount_like') ?
    JSON.parse(sessionStorage.getItem('user_discount_like')) : null
}

const discountReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_DISCOUNTS:
            sessionStorage.setItem('discounts', JSON.stringify(action.discounts));
            return {
                ...state,
                discounts: action.discounts
            };

        case SET_DISCOUNT_PACKAGES:
            sessionStorage.setItem('discount_packages', JSON.stringify(action.discount_packages));
            return {
                ...state,
                discount_packages: action.discount_packages
            };

        case SET_DISCOUNT_REVIEWS:
            sessionStorage.setItem('reviews', JSON.stringify(action.reviews));
            return {
                ...state,
                reviews: action.reviews
            };

        case SET_DISCOUNT_MEDIA:
            sessionStorage.setItem('discount_media', JSON.stringify(action.discount_media));
            return {
                ...state,
                discount_media: action.discount_media
            };

        case SET_CATEGORIES:
            sessionStorage.setItem('categories', JSON.stringify(action.categories));
            return {
                ...state,
                categories: action.categories,
            };

        case SET_CREATE_DISCOUNT_STATUS:
            return {
                ...state,
                createDiscountStatus: action.createDiscountStatus,
            };
            
        case SET_CART_ITEMS:
            sessionStorage.setItem('cartItems', JSON.stringify(action.cartItems));
            // sessionStorage.setItem('totalAmount', JSON.stringify(updatedTotalAmount));
            return {
                ...state,
                items: action.cartItems,
                totalAmount: action.totalAmount
            };

        case SET_WISH_LIST:
            sessionStorage.setItem('wishlist', JSON.stringify(action.wishlist));
            return {
                ...state,
                wishlist: action.wishlist
            };

        case USER_DISCOUNT_LIKE:
            sessionStorage.setItem('user_discount_like', JSON.stringify(action.user_discount_like));
            return {
                ...state,
                user_discount_like: action.user_discount_like
                };

        case SET_SEARCH_RESULT:
            sessionStorage.setItem('search_result', JSON.stringify(action.search_result));
            return {
                ...state,
                search_result: action.search_result
            };

        default:
            return state;
    }
}


export default discountReducer;