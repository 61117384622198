
export const SET_USER = "SET_USER";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_ORDER = "SET_USER_ORDER";
export const SET_USER_TICKETS = "SET_USER_TICKETS";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const SET_USER_IS_FOLLOWER = "SET_USER_IS_FOLLOWER";
export const USER_DISCOUNT_LIKE = "USER_DISCOUNT_LIKE";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";
export const GET_DISCOUNTS = "GET_DISCOUNTS";
export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const SET_DISCOUNT_PACKAGES = "SET_DISCOUNT_PACKAGES";
export const SET_DISCOUNT_MEDIA = "SET_DISCOUNT_MEDIA";
export const SET_DISCOUNT_REVIEWS = "SET_DISCOUNT_REVIEWS";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_CATEGORIES = "GET_CATEGORIES";
export const SET_CREATE_DISCOUNT_STATUS = "SET_CREATE_DISCOUNT_STATUS";
export const SET_UPDATE_DISCOUNT_STATUS = "SET_UPDATE_DISCOUNT_STATUS";
export const SET_ERRORS= "SET_ERRORS";
export const SET_PAYMENT= "SET_PAYMENT";
export const SET_PREVIOUS_URL= "SET_PREVIOUS_URL";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_WISH_LIST = "SET_WISH_LIST";
export const SET_ORGANIZER_DISCOUNTS = "SET_ORGANIZER_DISCOUNTS";
export const SET_ORGANIZER = "SET_ORGANIZER"; 
export const SET_ANALYTICS = "SET_ANALYTICS"; 
export const SET_ORGANIZER_NOTIFICATIONS = "SET_ORGANIZER_NOTIFICATIONS";
